.heroimage {
  padding: 190px 0 0;
  width: 100%;
  height: 500px;
  position: relative;
  box-shadow: none;
}
html.page-home .heroimage {
  height: 800px;
  clip-path: ellipse(1500px 800px at top);
}
html:not(.page-home) .heroimage {
  margin-bottom: 50px;
}
html.hasheadervideo .heroimage {
  margin-bottom: 0;
}
.heroimage__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {
  .heroimage__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.heroimage__title {
  color: #fff;
  max-width: 766px;
}
.heroimage__col--right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heroimage__logo {
  width: 180px;
  display: block;
}
.heroimage__description {
  font-family: "Montserrat", "sans-serif";
  font-weight: 300;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.4px;
  line-height: 30px;
  max-width: 542px;
}
html.page-home .heroimage__description {
  margin-left: 42px;
}
@media screen and (max-width: 1200px) {
  html.page-home .heroimage__description {
    margin-left: 0;
  }
}
.heroimage__homepageline {
  position: absolute;
  top: 425px;
  left: 50%;
  transform: translateX(-695px);
}
.heroimage .zipchecker--small {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
.heroimage__videocontainergrid {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  margin-top: -63px;
  margin-bottom: 90px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .heroimage__videocontainergrid {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.heroimage__videocontainer {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(89, 67, 80, 0.5);
}
.heroimage__videocontainer::before, .heroimage__videocontainer::after {
  content: "";
  display: table;
  clear: both;
  width: 1px;
  margin-left: -1px;
}
.heroimage__videocontainer::before {
  float: left;
  padding-bottom: 50.75%;
}
.heroimage__bgvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* 1. No object-fit support: */
  /* 2. If supporting object-fit, overriding (1): */
  display: block;
  z-index: 10;
}
@media (min-aspect-ratio: 16/9) {
  .heroimage__bgvideo {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .heroimage__bgvideo {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .heroimage__bgvideo {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.heroimage__video-circle {
  background: #f40076;
  border-radius: 100%;
  width: 182px;
  height: 182px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(calc(50% - 10px), calc(-50% + 20px));
  mix-blend-mode: multiply;
}
@media screen and (max-width: 1370px) {
  .heroimage__video-circle {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .heroimage {
    height: auto !important;
    padding-top: 140px;
    padding-bottom: 80px;
  }
  html.hasheadervideo .heroimage {
    padding-bottom: 120px;
  }
}