.emb-logoslider {
  position: relative;
}
.emb-logoslider__slide {
  width: 282px !important;
  height: 282px !important;
  background: #ffffff;
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.emb-logoslider__slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.emb-logoslider__navigationcontainer {
  display: flex;
  justify-content: center;
  position: relative;
  top: -10px;
  z-index: 10;
}
.emb-logoslider__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: #f2f1f2;
  margin: 0 12px;
}
.emb-logoslider__line {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translate(-698px, -97px);
}
html.page-home .emb-logoslider__line {
  display: block;
}
.emb-logoslider .swiper-container {
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 20;
}