.emb-sectors {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {
  .emb-sectors {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-sectors__title {
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  font-size: 20px;
  color: #4c4c4c;
  margin: 0 0 40px;
}
.emb-sectors__sectors {
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
  margin-left: -25px;
}
.emb-sectors__sectors .emb-sectors__sector {
  flex: 1 0 220px;
  margin-top: 25px;
  margin-left: 25px;
}
.emb-sectors__sector {
  height: 220px;
  position: relative;
  display: flex;
  padding-bottom: 15px;
  align-items: flex-end;
  justify-content: center;
  text-decoration: none;
}
.emb-sectors__sectortitle {
  font-size: 12px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  color: #fff;
  text-align: center;
}