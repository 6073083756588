.emb-googlemaps {
  display: flex;
}
.emb-googlemaps__map {
  flex: 1;
}
.emb-googlemaps__mapcontainer {
  height: 400px;
}
.emb-googlemaps__text {
  width: 306px;
  padding-left: 102px;
  line-height: 27px;
}
.emb-googlemaps__text > *:first-child {
  margin-top: 0;
}
.emb-googlemaps__text b,
.emb-googlemaps__text strong {
  color: #f40076;
}
.emb-googlemaps__text a {
  text-decoration: none;
  color: inherit;
}
html.non-touch .emb-googlemaps__text a:hover {
  text-decoration: underline;
}
.emb-googlemaps__social {
  margin: 40px 0 0;
  display: flex;
}
.emb-googlemaps__social > a {
  margin-right: 35px;
  display: flex;
  align-items: center;
}
.emb-googlemaps__social svg {
  display: block;
}
.emb-googlemaps__social .svg-facebook,
.emb-googlemaps__social .svg-twitter,
.emb-googlemaps__social .svg-linkedin {
  fill: #f40076;
}
@media screen and (max-width: 768px) {
  .emb-googlemaps__text {
    padding-left: 40px;
  }
}
@media screen and (max-width: 650px) {
  .emb-googlemaps {
    flex-direction: column;
  }
  .emb-googlemaps__map {
    order: 2;
  }
  .emb-googlemaps__mapcontainer {
    height: 300px;
  }
  .emb-googlemaps__text {
    order: 1;
    margin-top: 0;
    margin-bottom: 40px;
    width: 100%;
    padding-left: 0;
  }
}