main > .wh-form {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
.wh-form input[type=text],
.wh-form input[type=email],
.wh-form input[type=url],
.wh-form input[type=date],
.wh-form input[type=search],
.wh-form input[type=number],
.wh-form textarea {
  font-size: 32px;
  line-height: 100%;
  box-shadow: none;
  background: none;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #000;
  color: #f40076;
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  letter-spacing: 0.01px;
  outline: none;
  padding: 0 0 5px 0;
  transition: border 0.3s;
}
.wh-form input[type=text]::placeholder,
.wh-form input[type=email]::placeholder,
.wh-form input[type=url]::placeholder,
.wh-form input[type=date]::placeholder,
.wh-form input[type=search]::placeholder,
.wh-form input[type=number]::placeholder,
.wh-form textarea::placeholder {
  opacity: 0.2;
}
.wh-form input[type=text]::-ms-placeholder,
.wh-form input[type=email]::-ms-placeholder,
.wh-form input[type=url]::-ms-placeholder,
.wh-form input[type=date]::-ms-placeholder,
.wh-form input[type=search]::-ms-placeholder,
.wh-form input[type=number]::-ms-placeholder,
.wh-form textarea::-ms-placeholder {
  opacity: 0.2;
}
.wh-form input[type=text]:focus,
.wh-form input[type=email]:focus,
.wh-form input[type=url]:focus,
.wh-form input[type=date]:focus,
.wh-form input[type=search]:focus,
.wh-form input[type=number]:focus,
.wh-form textarea:focus {
  border-color: #f40076 !important;
}
.wh-form .wh-form__textarea:focus-visible,
.wh-form .wh-form__textinput:focus-visible {
  outline: none !important;
}
.wh-form textarea {
  min-height: 150px;
}
.wh-form__richtext {
  width: 100%;
}
.wh-form__label, .hbspt-form label {
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  color: #4f3b46;
}
.wh-form__fieldgroup {
  margin: 0 0 50px;
}
.wh-form__fieldgroup--required label::after {
  content: "*";
}
.wh-form__fieldgroup--error .wh-form__label, .wh-form__fieldgroup--error .hbspt-form label, .hbspt-form .wh-form__fieldgroup--error label {
  color: #d00;
}
.wh-form__fieldgroup--error input[type=text],
.wh-form__fieldgroup--error input[type=email],
.wh-form__fieldgroup--error input[type=url],
.wh-form__fieldgroup--error input[type=date],
.wh-form__fieldgroup--error input[type=search],
.wh-form__fieldgroup--error input[type=number],
.wh-form__fieldgroup--error textarea {
  border-color: #d00;
}
.wh-form__error, html .hbspt-form .hs-error-msgs,
html .hbspt-form .hs-error-msg,
html .hbspt-form .hs_error_rollup li * {
  margin-top: 10px;
  color: #d00;
  font-weight: 700;
}
.wh-form__buttongroup--alignright {
  text-align: right;
}
.wh-form__imgedit {
  outline: none;
}
.wh-form__imgedit .wh-form__imgeditholder {
  display: inline-block;
  position: relative;
  border: 1px solid black;
  width: 100px;
  height: 100px;
}
.wh-form__imgedit .wh-form__imgeditholder::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  font-size: 24px;
  content: "\f03e";
  font-family: FontAwesome;
  height: auto;
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder {
  width: 250px;
  height: 250px;
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form__imgeditimg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.wh-form__imgeditdelete::after {
  color: #d00;
  content: "Afbeelding verwijderen";
  display: block;
  font-size: 0.8em;
  cursor: pointer;
}
.wh-form--white .wh-form__label, .wh-form--white .hbspt-form label, .hbspt-form .wh-form--white label {
  color: #fff;
}
.wh-form--white .wh-form__error, .wh-form--white html .hbspt-form .hs-error-msgs, html .hbspt-form .wh-form--white .hs-error-msgs,
.wh-form--white html .hbspt-form .hs-error-msg,
html .hbspt-form .wh-form--white .hs-error-msg,
.wh-form--white html .hbspt-form .hs_error_rollup li *,
html .hbspt-form .hs_error_rollup li .wh-form--white * {
  color: #fff;
}
.wh-form--white .wh-form__fieldline {
  border-color: rgba(255, 255, 255, 0.8) !important;
}
.wh-form--white .wh-form__fieldgroup--pulldown .wh-form__fieldline::after {
  background: url("../../web/img/pulldown-arrow-white.svg") !important;
}
.wh-form--white .wh-form__fieldgroup--pulldown .wh-form__fieldline select {
  color: #fff !important;
}
.wh-form--white .wh-form__fieldgroup--pulldown .wh-form__fieldline select option {
  color: #000 !important;
}
.wh-form--white input[type=text],
.wh-form--white input[type=email],
.wh-form--white input[type=url],
.wh-form--white input[type=date],
.wh-form--white input[type=search],
.wh-form--white input[type=number],
.wh-form--white textarea {
  color: #fff;
}
.wh-form--white input[type=text], .wh-form--white input[type=text]:focus,
.wh-form--white input[type=email],
.wh-form--white input[type=email]:focus,
.wh-form--white input[type=url],
.wh-form--white input[type=url]:focus,
.wh-form--white input[type=date],
.wh-form--white input[type=date]:focus,
.wh-form--white input[type=search],
.wh-form--white input[type=search]:focus,
.wh-form--white input[type=number],
.wh-form--white input[type=number]:focus,
.wh-form--white textarea,
.wh-form--white textarea:focus {
  border-color: #fff !important;
}
.wh-form--labelsinfields .wh-form__fieldgroup {
  position: relative;
  margin-bottom: 30px;
}
.wh-form--labelsinfields .wh-form__fieldgroup--active .wh-form__label, .wh-form--labelsinfields .wh-form__fieldgroup--active .hbspt-form label, .hbspt-form .wh-form--labelsinfields .wh-form__fieldgroup--active label {
  font-size: 12px;
  transform: translateY(-30px);
}
.wh-form--labelsinfields .wh-form__label, .wh-form--labelsinfields .hbspt-form label, .hbspt-form .wh-form--labelsinfields label {
  position: absolute;
  top: 10px;
  left: 0;
  pointer-events: none;
  transition: transform 0.3s, font-size 0.3s;
}
.wh-form--labelsinfields .wh-form__error, .wh-form--labelsinfields html .hbspt-form .hs-error-msgs, html .hbspt-form .wh-form--labelsinfields .hs-error-msgs,
.wh-form--labelsinfields html .hbspt-form .hs-error-msg,
html .hbspt-form .wh-form--labelsinfields .hs-error-msg,
.wh-form--labelsinfields html .hbspt-form .hs_error_rollup li *,
html .hbspt-form .hs_error_rollup li .wh-form--labelsinfields * {
  display: none;
}
.wh-form .wh-form__fieldgroup--pulldown .wh-form__fieldline {
  padding: 0;
  margin: 0;
  border: 2px solid #000;
  width: 100%;
  height: 56px;
  max-width: 500px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  border-radius: 0;
}
.wh-form .wh-form__fieldgroup--pulldown .wh-form__fieldline::after {
  content: "";
  background: url("../../web/img/pulldown-arrow.svg");
  width: 17px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none;
}
.wh-form .wh-form__fieldgroup--pulldown .wh-form__fieldline select {
  cursor: pointer;
  color: #000;
  font-size: 16px;
  padding: 16px 8px 18px 25px;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.wh-form .wh-form__fieldgroup--pulldown .wh-form__fieldline select:focus {
  outline: none;
}

.hbspt-form {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
}
@media screen and (max-width: 996px) {
  .hbspt-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.hbspt-form fieldset {
  margin: 0 0 50px !important;
}
.hbspt-form .form-columns-1,
.hbspt-form .form-columns-2 {
  width: 100%;
  max-width: initial;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.hbspt-form .form-columns-1 > .hs-form-field,
.hbspt-form .form-columns-2 > .hs-form-field {
  width: calc(50% - 30px) !important;
  float: none !important;
}
.hbspt-form .form-columns-1 > .hs-form-field {
  width: 100% !important;
}
.hbspt-form .input {
  margin-right: 0 !important;
}
html .hbspt-form .hs-error-msgs,
html .hbspt-form .hs-error-msg,
html .hbspt-form .hs_error_rollup li * {
  color: #d00 !important;
}
.hbspt-form .hs-input {
  font-size: 32px;
  line-height: 100%;
  box-shadow: none;
  background: none;
  width: 100% !important;
  border: 0;
  border-bottom: 1px solid #000;
  color: #f40076;
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  letter-spacing: 0.01px;
  outline: none;
  padding: 0 0 5px 0;
  transition: border 0.3s;
}
@media screen and (max-width: 700px) {
  .hbspt-form .form-columns-1,
  .hbspt-form .form-columns-2 {
    width: 100%;
    max-width: initial;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .hbspt-form .form-columns-1 > *,
  .hbspt-form .form-columns-2 > * {
    margin-bottom: 2rem;
  }
  .hbspt-form .form-columns-1 > .hs-form-field,
  .hbspt-form .form-columns-2 > .hs-form-field {
    width: 100% !important;
  }
  .hbspt-form .hs-input {
    font-size: 24px;
    line-height: 100%;
  }
}

html.page-webhareform.page-form-connect-subscribe main > .wh-form .wh-form__fieldgroup {
  width: 100%;
}
html.page-webhareform.page-form-connect-subscribe .footer__line.websiterouteline {
  display: none;
}