.zipresult {
  position: fixed;
  width: 100%;
  max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 1000px));
  background: #fff;
  height: 745px;
  max-height: 100vh;
  z-index: 40000;
  transition: transform 0.5s, opacity 0.6s;
  box-shadow: 0 0 40px 0 rgba(89, 67, 80, 0.5);
  display: none;
  opacity: 0;
}
html.pageready .zipresult {
  display: flex;
}
html.isie .zipresult {
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
}
html.zipresult-active .zipresult {
  transform: translate(-50%, -50%);
  opacity: 1;
  pointer-events: auto;
}
.zipresult__leftpart {
  flex: 1;
  box-shadow: 0 0 40px 0 rgba(89, 67, 80, 0.5);
  position: relative;
}
.zipresult__image {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.zipresult__rightpart {
  width: 588px;
  padding: 75px 48px 35px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.zipresult__textcontainer {
  text-align: center;
  margin: 0 0 30px;
  display: none;
}
.zipresult__title {
  color: #5a5a5a;
}
.zipresult__text .pink {
  color: #f40076;
}
.zipresult__text a {
  text-decoration: none;
  color: #f40076;
  font-weight: 700;
}
html.non-touch .zipresult__text a:hover {
  text-decoration: underline;
}
.zipresult #close-zipresult {
  position: absolute;
  top: 30px;
  right: 40px;
  transition: transform 0.4s;
  transform-origin: center;
}
.zipresult #close-zipresult svg {
  display: block;
}
html.non-touch .zipresult #close-zipresult:hover {
  transform: rotate(90deg);
}
.zipresult[data-type=yes] .zipresult__image--yes,
.zipresult[data-type=yes] .zipresult__textcontainer--yes {
  display: block;
}
.zipresult[data-type=maybe] .zipresult__image--maybe,
.zipresult[data-type=maybe] .zipresult__textcontainer--maybe {
  display: block;
}
.zipresult[data-type=no] .zipresult__image--no,
.zipresult[data-type=no] .zipresult__textcontainer--no {
  display: block;
}
.zipresult .wh-form__page[data-wh-form-pagerole=thankyou] {
  text-align: center;
}
@media screen and (max-width: 600px) {
  .zipresult__rightpart {
    padding-left: 25px;
    padding-right: 25px;
  }
}

html.zipresult-active header {
  background: none !important;
}
html.zipresult-active .header {
  opacity: 0;
  pointer-events: none;
}