.emb-cta {
  background: #f7f7f7;
  padding: 40px 0;
}
.emb-cta__container {
  margin: 0 auto;
  width: 100%;
  max-width: 588px;
  text-align: center;
}
@media screen and (max-width: 588px) {
  .emb-cta__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-cta__title {
  color: #4f3b46;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.emb-cta__buttons {
  margin-top: 35px;
}
.emb-cta__buttons--two .emb-cta__button {
  margin: 0 12px;
}
@media screen and (max-width: 600px) {
  .emb-cta__buttons--two {
    display: flex;
    flex-direction: column;
  }
  .emb-cta__buttons--two .emb-cta__button {
    margin-bottom: 20px;
  }
  .emb-cta__buttons--two .emb-cta__button:last-child {
    margin-bottom: 0;
  }
}