.emb-networkmap {
  display: flex;
  justify-content: center;
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  aspect-ratio: 1400/700;
}

.embeddedobject--networkmap {
  margin: 0 auto;
}
.embeddedobject--networkmap #img-container img {
  width: 100%;
  height: 100%;
}