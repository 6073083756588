.sharebuttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sharebuttons__button {
  display: flex;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #bebebe;
  transition: border-color 0.3s, background 0.3s;
}
html.non-touch .sharebuttons__button:hover {
  background: #f40076;
  border-color: #f40076;
}
html.non-touch .sharebuttons__button:hover .svg-facebook,
html.non-touch .sharebuttons__button:hover .svg-linkedin,
html.non-touch .sharebuttons__button:hover .svg-twitter {
  fill: #fff;
}

.sharebuttons__container {
  margin-top: 5rem;
}
.sharebuttons__container .h2 {
  text-align: center;
  margin-bottom: 1rem;
}