.p-clients__scrollto {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {
  .p-clients__scrollto {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-clients__scrollto .wh-form__fieldgroup--pulldown .wh-form__fieldline {
  width: 350px;
}
.p-clients .carouselblock {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  background: #fff;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .p-clients .carouselblock {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-clients .carouselblock__grid {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  min-height: 320px;
  padding: 50px 0 0;
}
.p-clients .carouselblock__col--left {
  flex: 1;
  position: relative;
}
.p-clients .carouselblock__col--right {
  width: 486px;
  margin-left: 228px;
  padding: 0 30px 0 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.p-clients .carouselblock__image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 140px);
}
.p-clients .carouselblock__itemtitle {
  font-size: 20px;
  line-height: 100%;
  font-weight: 700;
  color: #4f3b46;
  margin: 0 0 10px;
}
.p-clients .carouselblock__itemsector {
  font-size: 13px;
  line-height: 100%;
  margin: 0 0 35px;
  color: #f40076;
}
.p-clients .carouselblock__itemdescription {
  margin: 0 0 10px;
}
.p-clients .carouselblock__link {
  margin: 10px 0 0;
}
.p-clients .carouselblock__link a {
  text-decoration: none;
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  color: #f40076;
  letter-spacing: 0.3px;
}
html.non-touch .p-clients .carouselblock__link a:hover {
  text-decoration: underline;
}
.p-clients .carouselblock__navigationcontainer {
  position: absolute;
  bottom: 0;
  right: -70px;
  display: flex;
}
.p-clients .carouselblock__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: #fff;
  outline: none;
}
.p-clients .carouselblock__navigation:last-child {
  background: #dedede;
}
@media screen and (max-width: 1100px) {
  .p-clients .carouselblock__col--right {
    margin-left: 50px;
  }
  .p-clients .carouselblock__navigationcontainer {
    right: 0;
  }
}
@media screen and (max-width: 800px) {
  .p-clients .carouselblock__grid {
    flex-direction: column;
  }
  .p-clients .carouselblock__col--left {
    order: 2;
  }
  .p-clients .carouselblock__image {
    display: none;
  }
  .p-clients .carouselblock__col--right {
    order: 1;
    width: 100%;
    margin-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 710px) {
  .p-clients .carouselblock__grid {
    min-height: 0;
  }
  .p-clients .carouselblock__col--right {
    padding: 40px 0;
  }
}
.p-clients .p-carousel {
  position: relative;
  margin-top: -140px;
  margin-bottom: 100px;
}
.p-clients .p-carousel__slider .slick-list {
  box-sizing: initial;
  padding: 140px 0 0;
}
.p-clients .p-carousel__slider .carouselblock {
  display: block !important;
}
.p-clients .p-sector {
  position: relative;
  margin: 0 0 120px;
}
.p-clients .p-sector::before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 145px);
  background-color: #f7f7f7;
  z-index: -1;
}
.p-clients .p-sector__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 40px 0 0;
}
@media screen and (max-width: 1200px) {
  .p-clients .p-sector__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-clients .p-sector__title {
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  color: #4c4c4c;
  margin-bottom: 40px;
}
.p-clients .p-sector__projects {
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
  margin-left: -25px;
}
.p-clients .p-sector__projects .p-sector__project {
  flex: 1 0 282px;
  margin-top: 25px;
  margin-left: 25px;
}
.p-clients .p-sector__project {
  height: 282px;
  position: relative;
  display: flex;
  padding: 0 15px 15px;
  align-items: flex-end;
  text-decoration: none;
}
.p-clients .p-sector__projecttitle {
  font-size: 14px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  color: #fff;
}

html.page-clients main {
  z-index: 0;
}
html.page-clients .heroimage {
  margin-bottom: 0;
}