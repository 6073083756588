.emb-photoalbum .contentwrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .emb-photoalbum .contentwrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-photoalbum__galleries {
  position: relative;
}
.emb-photoalbum__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.emb-photoalbum__gallery-top,
.emb-photoalbum__gallery-top .swiper-wrapper {
  width: 100%;
  height: 654px;
}
.emb-photoalbum__gallery-bottom {
  width: 100%;
  height: auto;
  position: relative;
}
.emb-photoalbum__gallery-bottom .swiper-container {
  margin: 27px 0 0;
}
.emb-photoalbum__thumbs {
  height: 100%;
}
.emb-photoalbum__thumbs,
.emb-photoalbum__thumbs .swiper-wrapper {
  width: 500px;
}
.emb-photoalbum__thumbitem {
  width: 305px;
  height: 203px;
  cursor: pointer;
}
.emb-photoalbum__thumbitem:not(.swiper-slide-active) {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.emb-photoalbum__next, .emb-photoalbum__prev {
  cursor: pointer;
  transition: opacity 0.3s;
}
.emb-photoalbum__next,
.emb-photoalbum__next > svg, .emb-photoalbum__prev,
.emb-photoalbum__prev > svg {
  display: block;
}
.emb-photoalbum__next.disabled, .emb-photoalbum__prev.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.emb-photoalbum__next {
  transform: scaleX(-1);
  margin-left: 22px;
}
.emb-photoalbum .swiper-button-next,
.emb-photoalbum .swiper-button-prev {
  background: #f7f7f7;
}
.emb-photoalbum .swiper-button-next {
  width: 73px;
  height: 75px;
  margin-top: -35px;
  right: calc((100% - 500px) / 2 - 36.5px);
}
.emb-photoalbum__gallery-top,
.emb-photoalbum__gallery-top .swiper-wrapper {
  width: 970px;
}
.emb-photoalbum .swiper-container {
  margin: 0;
}
.emb-photoalbum__galleries {
  position: relative;
}
.emb-photoalbum__gallery-bottom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 204px;
}
.emb-photoalbum__mobilenav {
  display: none;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #fff;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.emb-photoalbum__mobilenav--prev {
  transform: translateY(-50%) scaleX(-1);
  left: 0;
}
.emb-photoalbum__mobilenav--next {
  right: 0;
}
.emb-photoalbum__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  outline: none;
  background: #f7f7f7;
}
.emb-photoalbum__thumbs {
  max-height: 500px;
}
.emb-photoalbum__thumbs,
.emb-photoalbum__thumbs .swiper-wrapper {
  width: 100%;
}
.emb-photoalbum .swiper-button-next {
  position: absolute;
  top: auto;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  margin: 0 !important;
}
@media screen and (max-width: 1200px) {
  .emb-photoalbum__gallery-top,
  .emb-photoalbum__gallery-top .swiper-wrapper {
    width: 75%;
  }
  .emb-photoalbum__gallery-bottom {
    width: calc(25% - 24px);
  }
}
@media screen and (max-width: 768px) {
  .emb-photoalbum > .contentwrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .emb-photoalbum__gallery-top,
  .emb-photoalbum__gallery-top .swiper-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .emb-photoalbum > .contentwrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .emb-photoalbum__gallery-top,
  .emb-photoalbum__gallery-top .swiper-wrapper {
    max-height: 50vh;
  }
  .emb-photoalbum__gallery-bottom {
    display: none;
  }
  .emb-photoalbum__mobilenav {
    display: flex;
  }
}