.p-newsindex {
  padding: 0 0 100px;
  margin: 150px 0 0;
  position: relative;
}
.p-newsindex__rtd {
  margin-bottom: 100px;
}
.p-newsindex .newsblock {
  margin-bottom: 200px;
}
.p-newsindex .newsblock:last-child {
  margin-bottom: 0;
}

.p-newsdetails__rtd > *:first-child {
  margin-top: 0;
}
.p-newsdetails__backcontainer {
  width: 1200px;
  max-width: 100vw;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1200px) {
  .p-newsdetails__backcontainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-newsdetails__back {
  text-decoration: none;
  color: #f40076;
  display: inline-block;
}
html.non-touch .p-newsdetails__back:hover {
  text-decoration: underline;
}
.p-newsdetails__share {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .p-newsdetails__share {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-newsdetails__sharetitle {
  font-size: 55px;
  line-height: 100%;
  margin-bottom: 20px;
}
@media screen and (max-width: 1000px) {
  .p-newsdetails__backcontainer {
    position: static;
    transform: none;
    margin-bottom: 20px;
  }
}