@charset "UTF-8";
html {
  --header-25jaar-height: 100px;
  --header-25jaar-transform: 0;
}

html.page-home.show-25jaar .header {
  top: var(--header-top, 0);
}
html.page-home.show-25jaar .heroimage {
  top: var(--header-25jaar-height);
}
html.page-home.show-25jaar .header25jaar {
  display: flex;
}

.header25jaar {
  display: none;
  position: absolute;
  top: -100px;
  transform: translateY(var(--header-25jaar-transform));
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  max-height: 100px;
  width: 100%;
  height: 100px;
  padding: 1rem;
}
.header25jaar img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  cursor: pointer;
}

#navbar-offcanvas {
  z-index: 30000;
}

.offcanvas {
  z-index: 25000;
}
.offcanvas .nav-item {
  margin-bottom: 0;
}
.offcanvas .offcanvas-body {
  padding-left: 2rem;
}
.offcanvas .navbar-nav {
  row-gap: 16px;
}
.offcanvas .nav-link {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  line-height: 30px;
  color: #5a5a5a;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
}
@media screen and (max-width: 576px) {
  .offcanvas .nav-link {
    font-size: 11px;
    line-height: 16.5px;
  }
}
.offcanvas .nav-item .dropdown-toggle.show::after {
  transform: translateY(-12px) scaleY(-1) !important;
}
.offcanvas .dropdown-menu {
  border: 0;
  flex-direction: column;
}
.offcanvas .dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
}
.offcanvas .dropdown-item.active,
.offcanvas .dropdown-item:active {
  color: #f40076;
}
.offcanvas .nav-link.dropdown-toggle.show,
.offcanvas .nav-link.dropdown-toggle.selected,
.offcanvas .dropdown-menu > li.selected > a {
  color: #f40076;
}
.offcanvas .dropdown-menu > li {
  margin-bottom: 0;
}

.offcanvas-backdrop {
  z-index: 20000;
}

.offcanvas .nav-item .dropdown-toggle::after {
  transform: translateY(3px);
}

.offcanvas .nav-item .dropdown-toggle.show::after {
  transform: translateY(3px) scaleY(-1);
}

.offcanvas,
.dropdown-menu {
  --bs-dropdown-border-width: 0;
  --bs-dropdown-font-size: 28px;
  --bs-nav-link-font-size: var(--bs-dropdown-font-size);
  --bs-dropdown-link-active-bg: none;
  --bs-dropdown-link-active-color: inherit;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15000;
  transition: background 0.3s;
}
html.hideheader .header {
  border-bottom: 0;
}
.header__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 1200px) {
  .header__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.header__col--right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
  width: 180px;
  display: block;
  transition: opacity 0.3s;
}
html.isie .header__logo {
  display: none;
}
html.isie .header__logo--ie {
  display: block !important;
}
.header__menu {
  margin-right: 40px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.3s;
}
.header__menulink {
  text-decoration: none;
  color: #fff;
  margin-left: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
}
html.non-touch .header__menulink:hover {
  text-decoration: underline;
}
.header__hamburger {
  margin-top: 0;
  transform: translateY(8px);
  display: none;
}
.header__hamburger .hamburger {
  outline: none;
  padding-bottom: 7px;
  transition: background 0.3s;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  transform: translateY(-6px);
}
html.hideheader .header__col--left, html.hideheader .header__logo, html.hideheader .header__logolink, html.hideheader .header__menu {
  opacity: 0;
  pointer-events: none;
}
html.hideheader .header .hamburger {
  background: #f40076;
}
html.hideheader .header, html.hideheader .header__container {
  pointer-events: none;
}
html.hideheader .header__hamburger {
  pointer-events: auto;
}
html.menu-open .header {
  background: none;
  z-index: 30000;
}
html.menu-open .header .header__container {
  pointer-events: none;
}
html.menu-open .header .header__hamburger {
  pointer-events: auto;
}
html.menu-open .header .hamburger {
  background: none;
}
@media screen and (max-width: 1200px) {
  .header__menu {
    display: none;
  }
  .header__hamburger {
    display: block;
  }
}

html.non-touch .header__menulink--dropdown:hover .header__submenu {
  display: block;
}

/* Minimum size for interactive elements. */
/* Focus width. */
.hide {
  display: none !important;
}

.show {
  display: flex !important;
}

*:focus-visible,
.focus-visible {
  outline-color: var(--focus-outline-color, var(--active-theme-dark)) !important;
  outline-offset: var(--focus-outline-offset, calc(var(--focus-width, 0.1rem) * -1)) !important;
  outline-style: var(--focus-outline-style, dashed) !important;
  outline-width: var(--focus-width, 0.1rem) !important;
  box-shadow: 0 0 0 var(--focus-width, 0.1rem) inset var(--focus-box-shadow-color, var(--active-theme-light)) !important;
}

:root {
  /* Colours. */
  --black: hsl(0, 0%, 5%);
  --grey: hsl(0, 0%, 50%);
  --grey-light: hsl(0, 0%, 75%);
  --grey-dark: hsl(0, 0%, 25%);
  --white: hsl(0, 0%, 95%);
  --pink: hsl(329, 85%, 48%);
  --pink-light: hsl(329, 85%, 60%);
  --pink-dark: hsl(329, 85%, 40%);
  --blue: hsl(224, 94%, 59%);
  --blue-light: hsl(224, 94%, 70%);
  --blue-dark: hsl(224, 94%, 35%);
  /* Light theme colours. */
  --light-theme-light: var(--white);
  --light-theme-dark: var(--black);
  --light-theme-grey-light: var(--grey-light);
  --light-theme-grey: var(--grey);
  --light-theme-grey-dark: var(--grey-dark);
  --light-theme-primary: var(--blue);
  --light-theme-primary-light: var(--blue-light);
  --light-theme-primary-dark: var(--blue-dark);
  --light-theme-secondary: var(--pink);
  --light-theme-secondary-light: var(--pink-light);
  --light-theme-secondary-dark: var(--pink-dark);
  /* Dark theme colours. */
  /* Active theme colours. */
  --active-theme-dark: var(--light-theme-dark);
  --active-theme-grey-light: var(--light-theme-grey-light);
  --active-theme-grey: var(--light-theme-grey);
  --active-theme-grey-dark: var(--light-theme-grey-dark);
  --active-theme-primary: var(--light-theme-primary);
  --active-theme-primary-light: var(--light-theme-primary-light);
  --active-theme-primary-dark: var(--light-theme-primary-dark);
  --active-theme-secondary: var(--light-theme-secondary);
  --active-theme-secondary-light: var(--light-theme-secondary-light);
  --active-theme-secondary-dark: var(--light-theme-secondary-dark);
  --menu-item-chevron-padding-x: 3px;
  --menu-item-color: #fff;
  --menu-item-spacing: 0;
}

nav {
  background: var(--nav-background, var(--active-theme-light));
  color: var(--nav-color, var(--active-theme-dark));
  grid-area: menu;
}
nav > button {
  margin-left: auto;
}
nav > ul {
  margin-bottom: 0;
}
nav ul {
  display: none;
  list-style: none;
  padding: 0;
  flex-direction: column;
  gap: var(--menu-item-spacing, 0.25rem);
  background: var(--menu-background, var(--nav-background, var(--active-theme-light)));
  color: var(--menu-background, var(--nav-color, var(--active-theme-dark)));
  width: 100%;
  min-width: max-content;
}
nav ul ul {
  --menu-background: var(--sub-menu-background, var(--active-theme-secondary-light));
  --menu-item-background: #f40076;
  --menu-item-color: #fff;
  --button-background: var(--sub-menu-item-background, var(--active-theme-secondary-light));
}
nav li {
  position: relative;
  padding: 0;
  min-width: max-content;
}
nav li > a {
  min-width: 44px;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--menu-item-padding, var(--menu-item-padding-y, 0.5rem) var(--menu-item-padding-x, 1rem));
  text-decoration: none;
  background: var(--menu-item-background, var(--nav-background, var(--active-theme-light)));
  color: var(--menu-item-color, var(--nav-color, var(--active-theme-dark)));
  transition: background 250ms linear, color 250ms linear;
  flex: 1 1;
}
nav li > a:has(+ button[aria-expanded=true]) {
  --menu-item-background: red;
  --menu-item-color: var(--menu-item-indicator-color, var(--active-theme-dark));
}
nav li > a:hover {
  --menu-item-background: #fff;
  --menu-item-color: #000;
}
nav li > a:hover + button {
  --button-background: var(--menu-item-indicator-background, var(--active-theme-secondary-light));
  --button-color: var(--menu-item-indicator-color, var(--active-theme-dark));
}
nav li > a:has(+ button[aria-expanded=true]):hover {
  --menu-item-background: blue;
  --menu-item-color: var(--menu-item-hover-color, var(--active-theme-light));
}
nav .dropdown > button::after, nav:not(.top-link-disclosure-menu) .dropdown > a::after, nav.top-link-disclosure-menu .dropdown .dropdown > a::after {
  content: "▾";
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .dropdown > button[aria-expanded=true], nav:not(.top-link-disclosure-menu) .dropdown > a[aria-expanded=true], nav.top-link-disclosure-menu .dropdown .dropdown > a[aria-expanded=true] {
  --button-background: var(--menu-item-expanded-background, var(--active-theme-secondary-dark));
  --button-color: var(--menu-item-expanded-color, var(--active-theme-light));
}
nav:not(.top-link-disclosure-menu) .dropdown > a::after, nav.top-link-disclosure-menu .dropdown .dropdown > a::after {
  padding-left: calc(var(--menu-item-chevron-padding-x, 1rem) * 2);
}
nav.top-link-disclosure-menu li {
  display: flex;
  flex-wrap: wrap;
}

nav.disclosure-menu > ul {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  margin-top: 0;
}

@media screen and (min-width: 68ch) {
  nav > button {
    display: none;
  }
  nav > ul,
  nav > ul.hide {
    display: flex !important;
  }
  nav li {
    width: auto;
    margin-bottom: 0;
  }
  nav > ul {
    flex-direction: row;
  }
  nav .dropdown ul {
    position: absolute;
    top: 100%;
    left: 0;
  }
  nav .dropdown ul .dropdown ul {
    top: 0;
    left: 100%;
  }
  nav .dropdown.dropdown-left ul {
    left: unset;
    right: 0;
  }
  nav .dropdown.dropdown-left ul .dropdown ul {
    left: unset;
    right: 100%;
  }
}
nav li > a:hover {
  color: #000;
}

nav > ul > li.selected {
  background-color: #fff;
}

nav > ul > li > a {
  padding-left: 1rem;
  padding-right: 1rem;
}
nav > ul > li > a.selected {
  color: #000;
}

nav > ul > li > ul > li > a.selected {
  background-color: #fff;
  color: #000;
}

html.addheaderbg .header {
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.header__mainlink--hasnotification,
.header__sublink--hasnotification {
  position: relative;
}
.header__mainlink--hasnotification > .header__notification,
.header__sublink--hasnotification > .header__notification {
  display: block;
}

.header__notification {
  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--color-pink);
  border-radius: 100%;
  transition: background 0.3s;
}

.header__notification--mainlink {
  top: 6px;
  right: 23px;
}

.header__notification--sublink {
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -3px;
}

.header__sublink--hasnotification.selected .header__notification--sublink {
  background-color: var(--color-pink);
}
html.non-touch .header__sublink--hasnotification:hover .header__notification--sublink {
  background-color: var(--color-pink);
}