/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-blog-index .c-filters__filterbuttons {
  margin-bottom: 2rem;
}

html.page-blog-details .blogger {
  background-color: red;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  width: calc(100vw - 2rem);
  display: grid;
  grid-template-areas: "imagecontainer imagecontainer" "name date";
  justify-content: center;
  padding: 1rem;
  gap: 0.5rem 1rem;
}
html.page-blog-details .blogger .blogger__imagecontainer {
  grid-area: imagecontainer;
}
html.page-blog-details .blogger .blogger__name {
  grid-area: name;
}
html.page-blog-details .blogger .blogger__date {
  grid-area: date;
}
html.page-blog-details .blogger__image-name-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
html.page-blog-details .blogger__name {
  white-space: nowrap;
  font-weight: 700;
}
html.page-blog-details .blogger__imagecontainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
html.page-blog-details .blogger__image,
html.page-blog-details .blogger__image-placeholder {
  width: 76px;
  aspect-ratio: 1;
  border-radius: 100%;
}
html.page-blog-details .blogger__image {
  object-fit: cover;
}
html.page-blog-details .blogger__image-placeholder {
  background: var(--color-pink);
}
html.page-blog-details .blogger__date {
  white-space: nowrap;
}
@media (max-width: 991.98px) {
  html.page-blog-details main {
    margin-top: 8rem;
  }
  html.page-blog-details .blogger {
    transform: translate(-50%, calc(50% + 1rem));
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  html.page-blog-details main {
    margin-top: 6rem;
  }
  html.page-blog-details .blogger {
    grid-template-columns: 76px 1fr min-content;
    grid-template-areas: "imagecontainer name date";
    gap: 16px;
    justify-content: space-between;
    padding: 25px 30px;
    width: 500px;
    max-width: 100vw;
  }
}