html.page-home .zipchecker {
  position: absolute;
  top: 660px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 576px) {
  html.page-home .zipchecker {
    position: static;
    transform: none;
  }
}

.p-home {
  margin-top: 131px;
}
.p-home__heading-clients {
  font-size: 40px !important;
  margin-top: 0;
  position: relative;
}
.p-home__heading-clients::before {
  content: "03";
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  opacity: 0.5;
  font-size: 300px;
  color: #e1e1e1;
  letter-spacing: -15px;
  line-height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.p-home .p-textimage {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  min-height: 825px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .p-home .p-textimage {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-home .p-textimage__line {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: -1;
  transform: translateY(-70px);
}
.p-home .p-textimage__col--left {
  width: 588px;
  padding-right: 78px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
.p-home .p-textimage__col--right {
  flex: 1;
}
.p-home .p-textimage__title {
  font-size: 40px;
  line-height: 40px;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  color: #4f3b46;
  position: relative;
  padding: 0 0 0 100px;
  margin: 0 0 80px 0;
}
.p-home .p-textimage__title::before {
  content: "01";
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  opacity: 0.5;
  font-size: 300px;
  color: #cecece;
  letter-spacing: -15px;
  line-height: 200px;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.p-home .p-textimage__button {
  margin: 10px 0 0;
}
@media screen and (max-width: 1000px) {
  .p-home .p-textimage__col--left {
    width: 500px;
  }
}
@media screen and (max-width: 800px) {
  .p-home .p-textimage {
    display: block;
  }
  .p-home .p-textimage__col {
    width: 100%;
  }
  .p-home .p-textimage__col--left {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .p-home .p-textimage__col--right {
    margin-top: 30px;
    height: 500px;
  }
  .p-home .p-textimage__title {
    padding-left: 0;
    text-align: center;
  }
}
.p-home .p-darkfiber {
  margin: 100px 0 150px;
  position: relative;
}
.p-home .p-darkfiber__city {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {
  .p-home .p-darkfiber__city {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-home .p-darkfiber__city img {
  display: block;
  width: 100%;
}
.p-home .p-darkfiber__textcontainer {
  background: #000;
  color: #fff;
  padding: 150px 0 100px;
  clip-path: ellipse(1700px 100% at top);
  position: relative;
}
.p-home .p-darkfiber__topline, .p-home .p-darkfiber__line, .p-home .p-darkfiber__mole, .p-home .p-darkfiber__bottomcircle {
  position: absolute;
  pointer-events: none;
  z-index: 10;
}
.p-home .p-darkfiber__mole {
  top: 457px;
  left: 0;
}
@media screen and (max-width: 1300px) {
  .p-home .p-darkfiber__mole {
    top: 200px;
  }
}
@media screen and (max-width: 710px) {
  .p-home .p-darkfiber__mole {
    display: none;
  }
}
.p-home .p-darkfiber__topline {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 890px) {
  .p-home .p-darkfiber__topline {
    display: none;
  }
}
.p-home .p-darkfiber__bottomcircle {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  background-color: rgba(244, 0, 118, 0.8);
  bottom: 0;
  left: 50%;
  transform: translate(calc(-50% + 598px), 30px);
}
html.isie .p-home .p-darkfiber__bottomcircle {
  bottom: -34px;
  transform: translate(520px, 30px);
}
@media screen and (max-width: 1200px) {
  .p-home .p-darkfiber__bottomcircle {
    display: none;
  }
}
.p-home .p-darkfiber__titlecontainer {
  display: flex;
  justify-content: center;
}
.p-home .p-darkfiber__title {
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  font-size: 40px;
  line-height: 68px;
  color: #ffffff;
  position: relative;
  margin: 50px 0 100px;
}
.p-home .p-darkfiber__title::before {
  content: "02";
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  opacity: 0.22;
  font-size: 300px;
  color: #fff;
  letter-spacing: -15px;
  line-height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.p-home .p-darkfiber__body {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
  text-align: center;
}
@media screen and (max-width: 996px) {
  .p-home .p-darkfiber__body {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-home .p-darkfiber__button {
  margin: 40px 0 0;
  display: flex;
  justify-content: center;
}
.p-home .p-darkfiber__line {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -70px);
  margin-left: -327px;
}
.p-home .p-news {
  margin-top: 160px;
}
.p-home .p-news__titlecontainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
  padding: 0 110px 30px 0;
}
@media screen and (max-width: 1200px) {
  .p-home .p-news__titlecontainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-home .p-news__title {
  position: relative;
  font-size: 40px !important;
}
.p-home .p-news__title::before {
  font-size: 300px;
  line-height: 100%;
  content: "04";
  font-family: "Montserrat", "sans-serif";
  opacity: 0.5;
  color: #e1e1e1;
  letter-spacing: -15px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.p-home .p-news__slider .slick-list {
  box-sizing: initial;
  padding: 54px 0px;
}
.p-home .p-news__slider .newsblock {
  display: block !important;
}
@media screen and (max-width: 710px) {
  .p-home .p-news__titlecontainer {
    justify-content: center;
  }
}
@media screen and (max-width: 760px) {
  .p-home {
    margin-top: 100px;
  }
}
@media screen and (max-width: 650px) {
  .p-home__heading-clients {
    margin-top: 150px;
  }
}