.zipchecker {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
  background: #fff;
  height: 220px;
  box-shadow: 0 0 32px 0 rgba(84, 59, 73, 0.1);
  padding: 60px;
  z-index: 500;
}
@media screen and (max-width: 996px) {
  .zipchecker {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.zipchecker__formcontainer {
  padding: 0 120px;
}
.zipchecker__fieldscontainer {
  display: flex;
  align-items: flex-end;
}
.zipchecker__fieldscontainer > * {
  padding-right: 40px;
}
.zipchecker__fieldscontainer > *:last-child {
  padding-right: 0;
}
.zipchecker__tozipchecker {
  text-decoration: none;
  color: #f40076;
  display: inline-block;
  margin: 20px 0 0;
}
html.non-touch .zipchecker__tozipchecker:hover {
  text-decoration: underline;
}
.zipchecker .wh-form__fieldgroup {
  margin-bottom: 0;
}
.zipchecker .wh-form__error {
  position: absolute;
}
.zipchecker .wh-form__button {
  font-size: 17px;
  padding: 0;
}
.zipchecker__addresslabel {
  font-size: 18px;
  line-height: 100%;
  font-weight: 800;
  color: #f40076;
  letter-spacing: 0.01px;
  margin: 0 0 15px;
}
.zipchecker__addresslabel--b {
  margin-top: 50px;
}
.zipchecker--small .wh-form__error {
  top: 5px;
}
@media screen and (max-width: 760px) {
  .zipchecker--small {
    height: auto;
    margin-top: 30px;
    position: static;
    transform: none;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .zipchecker--small .zipchecker__button {
    width: 100%;
    margin-top: 40px;
  }
  .zipchecker--small .zipchecker__fieldscontainer {
    flex-flow: row wrap;
  }
  .zipchecker--small .zipchecker__fieldscontainer > * {
    width: 33.3333%;
  }
  .zipchecker--small input[type=text],
  .zipchecker--small input[type=email],
  .zipchecker--small input[type=url],
  .zipchecker--small input[type=date],
  .zipchecker--small input[type=search],
  .zipchecker--small input[type=number],
  .zipchecker--small textarea {
    max-width: 100%;
  }
}
@media screen and (max-width: 585px) {
  .zipchecker--small .wh-form__fieldgroup {
    margin-bottom: 45px;
  }
  .zipchecker--small .wh-form__error {
    display: none;
  }
  .zipchecker--small .zipchecker__fieldscontainer > * {
    width: 50%;
  }
  .zipchecker--small .zipchecker__fieldscontainer > *:nth-child(1) {
    width: 100%;
  }
  .zipchecker--small .zipchecker__fieldscontainer > *:nth-child(1), .zipchecker--small .zipchecker__fieldscontainer > *:nth-child(3) {
    padding-right: 0;
  }
  .zipchecker--small .zipchecker__fieldscontainer > .zipchecker__button {
    width: 100%;
  }
  .zipchecker--small .zipchecker__button {
    margin-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .zipchecker--small .zipchecker__fieldscontainer > * {
    width: 100%;
    padding-right: 0;
  }
}
.zipchecker--big {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  height: 480px;
  height: 526px;
}
@media screen and (max-width: 1200px) {
  .zipchecker--big {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.zipchecker--big .zipchecker__button {
  margin-top: 50px;
}
@media screen and (max-width: 800px) {
  .zipchecker--big .zipchecker__formcontainer {
    padding-right: 0;
  }
}
@media screen and (max-width: 665px) {
  .zipchecker--big .zipchecker__sidebarchar {
    display: none;
  }
  .zipchecker--big .zipchecker__formcontainer {
    padding-left: 0;
  }
}
@media screen and (max-width: 560px) {
  .zipchecker--big {
    height: auto;
    position: static;
  }
  .zipchecker--big .wh-form__fieldgroup {
    margin-bottom: 45px;
  }
  .zipchecker--big .wh-form__error {
    display: none;
  }
  .zipchecker--big .zipchecker__fieldscontainer {
    flex-flow: row wrap;
  }
  .zipchecker--big .zipchecker__fieldscontainer > * {
    width: 50%;
  }
  .zipchecker--big .zipchecker__fieldscontainer > *:nth-child(1) {
    width: 100%;
  }
  .zipchecker--big .zipchecker__fieldscontainer > *:nth-child(1), .zipchecker--big .zipchecker__fieldscontainer > *:nth-child(3) {
    padding-right: 0;
  }
  .zipchecker--big .zipchecker__fieldscontainer > .zipchecker__button {
    width: 100%;
  }
  .zipchecker--big .zipchecker__button {
    margin-top: 0;
  }
  .zipchecker--big .zipchecker__addresslabel--b {
    margin-top: 10px;
  }
  .zipchecker--big .wh-form__fieldgroup {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 400px) {
  .zipchecker--big .zipchecker__fieldscontainer > * {
    width: 100%;
    padding-right: 0;
  }
}
.zipchecker__sidebarchar {
  font-size: 60px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  color: #4f3b46;
  position: absolute;
  top: 107px;
  left: 60px;
}
.zipchecker__sidebarchar--a::after {
  content: "";
  display: block;
  height: 80px;
  width: 1px;
  background: #4f3b46;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-2px, 14px);
}
.zipchecker__sidebarchar--b {
  transform: translateY(168px);
}