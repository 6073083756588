.embeddedobject.embeddedobject--employees {
  padding-bottom: 91px;
}

.emb-employees {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
  background: #f7f7f7;
  padding: 40px 0 50px;
  position: relative;
}
@media screen and (max-width: 996px) {
  .emb-employees {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-employees::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #f40076;
  border-radius: 100%;
  width: 182px;
  height: 182px;
  z-index: 10;
}
.emb-employees__text {
  margin: 0 auto;
  width: 100%;
  max-width: 690px;
  text-align: center;
}
.emb-employees__title {
  margin-top: 0;
}
.emb-employees__intro {
  margin: 0 0 100px;
}
.emb-employees__grid {
  width: 1200px;
  position: relative;
  left: -81px;
  max-width: 100vw;
}
.emb-employees__grid .grid-item {
  width: calc(33.33333% - 24px);
}
@media screen and (max-width: 1200px) {
  .emb-employees {
    padding-right: 0;
  }
  .emb-employees__grid {
    width: 100%;
    left: 11px;
  }
  .emb-employees .grid-item {
    width: calc(50% - 24px);
  }
}
@media screen and (max-width: 650px) {
  .emb-employees .grid-item {
    width: 100%;
  }
  .emb-employees__grid {
    width: 90%;
    left: 10px;
  }
}
.emb-employees .e-employee {
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
  position: relative;
  margin-bottom: 80px;
  padding-bottom: 50px;
}
.emb-employees .e-employee__photo {
  position: absolute;
  width: 240px;
  height: 240px;
  top: -40px;
  left: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.emb-employees .e-employee__description {
  padding: 233px 60px 0;
}
.emb-employees .e-employee__nameposition {
  font-size: 14px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  color: #fff;
  padding: 0 10px 15px 10px;
  position: relative;
  z-index: 2;
}
.emb-employees .e-employee__name {
  font-weight: 900;
}
.emb-employees .e-employee__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .emb-employees .e-employee__description {
    padding-left: 40px;
    padding-right: 40px;
  }
  .emb-employees .e-employee__photo {
    left: 40px;
    width: calc(100% - 80px);
  }
}