.emb-pinktextblock {
  background: #f40076;
  padding: 40px 0;
  color: #fff;
  position: relative;
}
.emb-pinktextblock__container {
  margin: 0 auto;
  width: 100%;
  max-width: 588px;
  text-align: center;
}
@media screen and (max-width: 588px) {
  .emb-pinktextblock__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-pinktextblock__title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.emb-pinktextblock__rtd .embeddedobject--button {
  margin-top: 35px;
}
.emb-pinktextblock__line {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;
  transform: translate(611px, -55px);
}
html.page-home .emb-pinktextblock__line {
  display: block;
}