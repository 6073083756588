.newsletter {
  width: 600px;
  background: #f40076;
  color: #fff;
  max-width: 100vw;
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
  padding: 40px;
}
.newsletter__title {
  font-size: 48px;
  line-height: 52px;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  margin: 0 0 30px;
}
@media screen and (max-width: 576px) {
  .newsletter__title {
    font-size: 26.4px;
    line-height: 28.6px;
  }
}
.newsletter__fieldscontainer {
  display: flex;
  justify-content: space-between;
}
.newsletter__field {
  flex: 1;
  padding-right: 110px;
}
.newsletter__field input[type=text],
.newsletter__field input[type=email],
.newsletter__field input[type=url],
.newsletter__field input[type=date],
.newsletter__field input[type=search],
.newsletter__field input[type=number],
.newsletter__field textarea {
  width: 100%;
  max-width: 100%;
}
.newsletter__button {
  padding: 30px 0 0;
}
.newsletter .wh-form__fieldgroup {
  margin-bottom: 0;
}
.newsletter .wh-form__error {
  position: absolute;
  color: #000;
}
.newsletter .wh-form__fieldgroup--pulldown .wh-form__fieldline {
  max-width: 100% !important;
}
.newsletter.busy {
  pointer-events: none;
}
.newsletter .wh-form__page {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.newsletter .wh-form__navbuttons {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 830px) {
  .newsletter__field {
    padding-right: 40px;
  }
}
@media screen and (max-width: 730px) {
  .newsletter {
    padding: 50px;
  }
  .newsletter__fieldscontainer {
    display: block;
  }
  .newsletter__field {
    padding-right: 0;
  }
  .newsletter__button {
    padding-top: 55px;
  }
}
@media screen and (max-width: 500px) {
  .newsletter {
    padding-left: 25px;
    padding-right: 25px;
  }
  .newsletter__button .wh-form__button {
    min-width: initial;
    width: 100%;
  }
}
.newsletter .hs-form {
  display: flex;
}
.newsletter .hs-form-field {
  flex: 1;
  padding-right: 70px;
}
.newsletter .hs-button {
  border: 2px solid #fff;
}
.newsletter .hs-submit {
  display: flex;
  align-items: flex-end;
}
.newsletter .hs_error_rollup {
  display: none;
}
.newsletter .hs-error-msgs.inputs-list {
  position: absolute;
}
.newsletter .hs-error-msgs.inputs-list,
.newsletter .hs-error-msgs.inputs-list * {
  color: #fff !important;
}