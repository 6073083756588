.widget.widget--employees {
  padding-bottom: 91px;
}

.w-employeeswithphotos {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
  padding: 0 0 50px;
  position: relative;
}
@media screen and (max-width: 996px) {
  .w-employeeswithphotos {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.w-employeeswithphotos__text {
  margin: 0 auto;
  width: 100%;
  max-width: 690px;
  text-align: center;
}
.w-employeeswithphotos__title {
  margin-top: 0;
}
.w-employeeswithphotos__intro {
  margin: 0 0 3rem;
}
.w-employeeswithphotos .w-employeeswithphotos__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}
.w-employeeswithphotos .w-employeeswithphotos__griditem {
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
}
.w-employeeswithphotos .w-employeeswithphotos__name-position {
  padding: 1rem 3rem 1rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.w-employeeswithphotos .w-employeeswithphotos__name-position .js-employeeswithphotos-rotatephoto {
  display: none;
}
@media (hover: none) {
  .w-employeeswithphotos .w-employeeswithphotos__name-position .js-employeeswithphotos-rotatephoto {
    display: unset;
  }
}
.w-employeeswithphotos .w-employeeswithphotos__name-position .js-employeeswithphotos-rotatephoto {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.w-employeeswithphotos .w-employeeswithphotos__name-position .js-employeeswithphotos-rotatephoto svg {
  display: block;
  transform: scale(1.25);
}
.w-employeeswithphotos .w-employeeswithphotos__name {
  font-size: 20px;
  line-height: 100%;
  font-weight: 700;
}
.w-employeeswithphotos .w-employeeswithphotos__position {
  line-height: 20px;
}
.w-employeeswithphotos .w-employeeswithphotos__photocontainer {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  overflow: hidden;
}
html.non-touch .w-employeeswithphotos .w-employeeswithphotos__photocontainer--hover:hover .w-employeeswithphotos__photo--main {
  transform: translateX(-100%);
  opacity: 0.5;
}
.w-employeeswithphotos .w-employeeswithphotos__photocontainer--hover.activated .w-employeeswithphotos__photo--main {
  transform: translateX(-100%);
  opacity: 0.5;
}
.w-employeeswithphotos .w-employeeswithphotos__photo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.4s, opacity 0.25s;
  z-index: 20;
}
.w-employeeswithphotos .w-employeeswithphotos__photo--hover {
  z-index: 10;
}
.w-employeeswithphotos .w-employeeswithphotos__position {
  transform: translateY(-6px);
}