@import url("https://use.typekit.net/per5dzn.css");
@font-face {
  font-family: "gtwalsheim";
  font-weight: 300;
  src: url("./gt_walsheim_light-webfont.woff");
}
@font-face {
  font-family: "gtwalsheim";
  font-weight: 400;
  src: url("./gt_walsheim_regular-webfont.woff");
}
@font-face {
  font-family: "gtwalsheim";
  font-weight: 500;
  src: url("./gt_walsheim_medium-webfont.woff");
}
@font-face {
  font-family: "gtwalsheim";
  font-weight: 900;
  src: url("./gt_walsheim_black-webfont.woff");
}