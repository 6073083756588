.emb-quote__text {
  margin: 0 auto;
  width: 100%;
  max-width: 792px;
  font-size: 42px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 400;
  color: #f40076;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 792px) {
  .emb-quote__text {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width: 576px) {
  .emb-quote__text {
    font-size: 23.1px;
  }
}
.emb-quote__from {
  margin: 0 auto;
  width: 100%;
  max-width: 588px;
  font-size: 16px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 800;
  color: #4f3b46;
  text-align: center;
}
@media screen and (max-width: 588px) {
  .emb-quote__from {
    padding-left: 25px;
    padding-right: 25px;
  }
}