.emb-textimagecols {
  margin: 0 0 100px;
}
.emb-textimagecols__container {
  max-width: 1200px;
  margin: 0 auto;
  background: #f7f7f7;
  display: flex;
  justify-content: flex-end;
  padding: 100px 0 0;
  min-height: 518px;
}
.emb-textimagecols__cols {
  margin: 0 auto;
  width: 100%;
  max-width: 1098px;
  display: flex;
}
@media screen and (max-width: 1098px) {
  .emb-textimagecols__cols {
    padding-left: 25px;
    padding-right: 25px;
  }
}
html.isie .emb-textimagecols__cols {
  max-width: none;
  padding-left: 51px;
}
.emb-textimagecols__col--image {
  flex: 1;
  position: relative;
}
.emb-textimagecols__col--text {
  padding: 0 40px 0 90px;
  width: 588px;
}
.emb-textimagecols__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 520px;
  background-color: initial !important;
}
.emb-textimagecols__title {
  color: #000;
}
.emb-textimagecols__rtd {
  padding-bottom: 50px;
}
.emb-textimagecols__rtd .emb-button {
  text-align: left;
}
.emb-textimagecols--mirrored .emb-textimagecols__cols {
  flex-direction: row-reverse;
}
.emb-textimagecols--mirrored .emb-textimagecols__col--text {
  padding-left: 40px;
  padding-right: 90px;
}
@media screen and (max-width: 915px) {
  .emb-textimagecols__col--text {
    padding-left: 40px;
  }
  .emb-textimagecols--mirrored .emb-textimagecols__col--text {
    padding-right: 40px;
  }
}
@media screen and (max-width: 767px) {
  .emb-textimagecols__container {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 0;
  }
  .emb-textimagecols__col--image {
    display: none;
  }
  .emb-textimagecols__rtd {
    padding-bottom: 0;
  }
  .emb-textimagecols, .emb-textimagecols--mirrored {
    margin-bottom: 0;
  }
  .emb-textimagecols .emb-textimagecols__col--text, .emb-textimagecols--mirrored .emb-textimagecols__col--text {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}