.emb-photoslider__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .emb-photoslider__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-photoslider__slide {
  position: relative;
}
.emb-photoslider__image {
  width: 100%;
}
.emb-photoslider__image::before, .emb-photoslider__image::after {
  content: "";
  display: table;
  clear: both;
  width: 1px;
  margin-left: -1px;
}
.emb-photoslider__image::before {
  float: left;
  padding-bottom: 45.0751252087%;
}
.emb-photoslider__contentcontainer {
  display: flex;
  padding-left: 204px;
  position: relative;
}
.emb-photoslider__content {
  background: #fff;
  padding: 34px;
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
  width: 588px;
  position: relative;
  top: -150px;
}
.emb-photoslider__title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 900;
  color: #4f3b46;
  letter-spacing: 0.4px;
  margin: 0 0 30px;
}
.emb-photoslider__num {
  font-size: 22px;
  line-height: 100%;
  color: #f40076;
  margin: 0 0 0 5px;
}
.emb-photoslider__description {
  margin: 0 0 30px;
}
.emb-photoslider__link a {
  font-size: 18px;
  line-height: 100%;
  text-decoration: none;
  color: #f40076;
  font-weight: 900;
}
html.non-touch .emb-photoslider__link a:hover {
  text-decoration: underline;
}
.emb-photoslider__navigationcontainer {
  position: absolute;
  left: 100%;
  top: 80px;
  display: flex;
}
.emb-photoslider__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: #fff;
  outline: none;
}
.emb-photoslider__navigation:last-child {
  background: #f7f7f7;
}
.emb-photoslider__line {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 10;
  transform: translate(-128px, 40px);
}
html.page-home .emb-photoslider__line {
  display: block;
}
@media screen and (max-width: 1000px) {
  .emb-photoslider__contentcontainer {
    padding-left: 20px;
  }
}
@media screen and (max-width: 850px) {
  .emb-photoslider__contentcontainer {
    padding-left: 0;
    justify-content: center;
  }
  .emb-photoslider__content {
    width: 95%;
  }
  .emb-photoslider__navigationcontainer {
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
  }
}
@media screen and (max-width: 630px) {
  .emb-photoslider__content {
    top: 0;
  }
  .emb-photoslider__navigationcontainer {
    transform: translate(-50%, calc(-100% + 10px)) scale(0.8);
  }
}