.emb-supportcategory {
  margin: 0 auto;
  width: 100%;
  max-width: 1098px;
}
@media screen and (max-width: 1098px) {
  .emb-supportcategory {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-supportcategory__container {
  width: 100%;
  max-width: 894px;
  margin: 0 0 60px;
}
.emb-supportcategory__title {
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  color: #4c4c4c;
  margin-top: 0;
  margin-bottom: 30px;
}
.emb-supportcategory__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -24px;
  margin-left: -24px;
}
.emb-supportcategory__items .emb-supportcategory__item {
  flex: 1 0 282px;
  margin-top: 24px;
  margin-left: 24px;
}
.emb-supportcategory__item {
  height: 360px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-decoration: none;
}
.emb-supportcategory__itemtitle {
  font-size: 14px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  color: #ffffff;
  padding: 0 24px 24px 24px;
}