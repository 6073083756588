.menu {
  position: fixed;
  transform: translateX(100%);
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20000;
  background: #fff;
  padding: 150px 5vw 0 60px;
  transition: transform 0.3s ease-out;
  flex-direction: column;
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
  display: none;
}
html.pageready .menu {
  display: flex;
}
html.menu-open .menu {
  transform: translateX(0);
}
.menu__items {
  display: flex;
  flex-direction: column;
}
.menu__link {
  font-size: 30px;
  line-height: 30px;
  color: #5a5a5a;
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  text-decoration: none;
  margin: 0 0 20px;
}
@media screen and (max-width: 576px) {
  .menu__link {
    font-size: 16.5px;
    line-height: 16.5px;
  }
}
.menu__link--pink {
  color: #f40076;
}
.menu__link::after {
  content: "";
  width: 0px;
  transition: width 0.3s;
  position: relative;
  top: 5px;
  height: 6px;
  background: #f40076;
  display: block;
  border-radius: 15px;
}
@media screen and (min-width: 1201px) {
  .menu__link--hideondesktop {
    display: none;
  }
}
html.non-touch .menu__link:hover {
  color: #f40076;
}
html.non-touch .menu__link:hover::after {
  width: 60px;
}
.menu__malfunction {
  display: flex;
  flex-direction: column;
  margin: 55px 0 0 0;
}
.menu__malfunction::before {
  content: "";
  width: 40%;
  position: relative;
  top: -30px;
  height: 1px;
  background: #979797;
  display: block;
  border-radius: 15px;
}
.menu__malfunctionlabel {
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  color: #5a5a5a;
  max-width: 290px;
  margin: 0 0 15px;
}
.menu__contactlink {
  margin: 30px 0 25px;
}
.menu__contactlink a {
  font-size: 14px;
  line-height: 100%;
  text-decoration: none;
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  color: inherit;
}
html.non-touch .menu__contactlink a:hover {
  text-decoration: underline;
}
.menu__social a {
  display: inline-block;
  margin-right: 15px;
}
.menu__social svg {
  transform: scale(0.7);
}
@media screen and (max-width: 650px) {
  .menu {
    padding-top: 100px;
  }
}
@media screen and (max-width: 576px) {
  .menu .menu__malfunctionlabel {
    font-size: 16px;
    line-height: 100%;
  }
}

html.menu-open .header__col--left,
html.menu-open .header__menu {
  pointer-events: none;
}