.emb-twocolumnstext {
  background: #f40076;
  font-family: "proxima-nova", "sans-serif";
  padding: 120px 0;
  color: #ffffff;
}
.emb-twocolumnstext__title {
  font-size: 20px;
  line-height: 100%;
  font-weight: 900;
  margin: 0 0 45px;
}
.emb-twocolumnstext__container {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
}
@media screen and (max-width: 996px) {
  .emb-twocolumnstext__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.emb-twocolumnstext__cols {
  display: flex;
  justify-content: space-between;
}
.emb-twocolumnstext__col {
  width: 50%;
  font-size: 16px;
  line-height: 24px;
}
.emb-twocolumnstext__col--left {
  margin-right: 25px;
}
.emb-twocolumnstext__col--right {
  margin-left: 25px;
}
.emb-twocolumnstext__col .wh-rtd > *:first-child {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .emb-twocolumnstext {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .emb-twocolumnstext__cols {
    flex-direction: column;
  }
  .emb-twocolumnstext__col {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}