html,
body,
form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

datalist,
template {
  display: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

button {
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "proxima-nova", "sans-serif";
  font-size: 16px;
  color: #4f3b46;
  letter-spacing: 0.4px;
  line-height: 30px;
}

h1,
.h1 {
  font-size: 60px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 800;
  margin-top: 5px;
  margin-bottom: 0.4em;
}
@media screen and (max-width: 576px) {
  h1,
  .h1 {
    font-size: 33px;
  }
}

h2,
.h2 {
  font-size: 40px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 800;
}
@media screen and (max-width: 576px) {
  h2,
  .h2 {
    font-size: 22px;
  }
}

a {
  text-decoration: underline;
  transition: color 0.3s;
}

main > h1.heading1,
main > h2.heading2,
main > h3.heading3,
main > p.normal,
main > ul.unordered,
main > ol.ordered,
main > .page-back-link,
main > .sharebuttons__container,
main > .centercontainer,
main > .wh-rtd__tablewrap,
main > .wh-video,
.wh-rtd--main > h1.heading1,
.wh-rtd--main > h2.heading2,
.wh-rtd--main > h3.heading3,
.wh-rtd--main > p.normal,
.wh-rtd--main > ul.unordered,
.wh-rtd--main > ol.ordered,
.wh-rtd--main > .page-back-link,
.wh-rtd--main > .sharebuttons__container,
.wh-rtd--main > .centercontainer,
.wh-rtd--main > .wh-rtd__tablewrap,
.wh-rtd--main > .wh-video {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 800px;
}
@media screen and (max-width: 800px) {
  main > h1.heading1,
  main > h2.heading2,
  main > h3.heading3,
  main > p.normal,
  main > ul.unordered,
  main > ol.ordered,
  main > .page-back-link,
  main > .sharebuttons__container,
  main > .centercontainer,
  main > .wh-rtd__tablewrap,
  main > .wh-video,
  .wh-rtd--main > h1.heading1,
  .wh-rtd--main > h2.heading2,
  .wh-rtd--main > h3.heading3,
  .wh-rtd--main > p.normal,
  .wh-rtd--main > ul.unordered,
  .wh-rtd--main > ol.ordered,
  .wh-rtd--main > .page-back-link,
  .wh-rtd--main > .sharebuttons__container,
  .wh-rtd--main > .centercontainer,
  .wh-rtd--main > .wh-rtd__tablewrap,
  .wh-rtd--main > .wh-video {
    padding-left: 25px;
    padding-right: 25px;
  }
}

ul,
ol {
  padding-left: 1em;
}
ul li,
ol li {
  margin: 0 0 12px;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.ww-p-overview .c-filters__filtertitle::marker {
  display: none;
}
.ww-p-overview .c-filters__filterbuttons {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 1rem;
}
.ww-p-overview__indexgrid--blocks .ww-p-overview__indexcontent {
  padding: var(--ww-page-overview-block-content-padding);
}
@media (min-width: 576px) {
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indexitem {
    display: flex;
    margin: var(--ww-page-overview-block-margin);
    box-shadow: var(--ww-page-overview-block-box-shadow) !important;
  }
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indeximage {
    flex: 0 0 var(--ww-page-overview-block-image-width);
    margin-right: var(--ww-page-overview-block-image-margin);
  }
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indeximage-img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indextitle {
    font-size: 3rem;
    margin-top: 0;
  }
}

.ww-p-overviewpageitem__newsdate {
  font-weight: 700;
  margin-bottom: 1rem;
}

.ww-p-overview__button-filter {
  margin-bottom: 1rem;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* Widget: CTAs */
  --ww-widget-ctas-image-aspect-ratio: 56.25%; /* percentage: (H / B) * 100% (standaard is 9:16) */
  --ww-widget-ctas-image-max-width: 100%;
  --ww-widget-ctas-image-border-radius: 10px;
  --ww-widget-ctas-image-overlay: linear-gradient(
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 100%
  ); /* kan ook: rgba(0, 0, 0, 0.5) */
  --ww-widget-ctas-image-overlay-opacity-hover: 0.5;
  --ww-widget-ctas-image-transform-hover: scale(1.1);
  --ww-widget-ctas-grid-gap: 2rem;
  --ww-widget-ctas-col-box-shadow: inherit;
  --ww-widget-ctas-text-padding: 1rem;
  --ww-widget-ctas-text-background-color: #f2f2f5;
  --ww-widget-ctas-text-background-color-hover: #d5d5df;
  --ww-widget-ctas-text-color: #fff;
  --ww-widget-ctas-hasbgimage-padding-y: 3rem;
}

.widget--ctas.widget--hasbgimage {
  padding-top: var(--ww-widget-ctas-hasbgimage-padding-y);
  padding-bottom: var(--ww-widget-ctas-hasbgimage-padding-y);
}

.w-ctas {
  margin: 0 auto;
}
.w-ctas__container {
  display: grid;
  grid-gap: var(--ww-widget-ctas-grid-gap);
}
@media (min-width: 576px) {
  .w-ctas__container--4, .w-ctas__container--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .w-ctas__container--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .w-ctas__container--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .w-ctas__container--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.w-ctas__imagelinkcontainer {
  max-width: var(--ww-widget-ctas-image-max-width);
}
.w-ctas__imagelink {
  display: block;
  height: 0;
  position: relative;
  padding-bottom: var(--ww-widget-ctas-image-aspect-ratio);
}
.w-ctas__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.w-ctas__text {
  padding: var(--ww-widget-ctas-text-padding);
}
.w-ctas__text > * {
  margin-top: 0;
  margin-bottom: 1em;
}
.w-ctas__text h3 {
  font-size: 1.5rem;
}
.w-ctas__text h4 {
  font-size: 1.125rem;
}
.w-ctas__text .btn {
  margin-top: 1em;
}
.w-ctas__cta-readmore {
  min-width: var(--ww-button-min-width);
}
.w-ctas--blockstype-textbelow .w-ctas__text, .w-ctas--blockstype-bgcolor .w-ctas__text {
  background-color: var(--ww-widget-ctas-text-background-color);
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  max-width: 100% !important;
}
.w-ctas--blockstype-bgimage .w-ctas__col-inner, .w-ctas--blockstype-bgcolor .w-ctas__col-inner {
  position: relative;
  height: calc(100% - 2 * var(--ww-widget-ctas-text-padding));
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  margin-bottom: 0 !important;
  height: 100%;
}
.w-ctas--blockstype-bgimage .w-ctas__text, .w-ctas--blockstype-bgcolor .w-ctas__text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  pointer-events: none;
}
.w-ctas--blockstype-bgimage .w-ctas__text > *:first-child, .w-ctas--blockstype-bgcolor .w-ctas__text > *:first-child {
  margin-top: 0;
}
.w-ctas--blockstype-bgimage .w-ctas__text > *:last-child, .w-ctas--blockstype-bgcolor .w-ctas__text > *:last-child {
  margin-bottom: 0;
}
.w-ctas--blockstype-bgimage .w-ctas__text .btn, .w-ctas--blockstype-bgcolor .w-ctas__text .btn {
  display: none;
}
.w-ctas--blockstype-bgimage .w-ctas__text .widget--button .btn, .w-ctas--blockstype-bgcolor .w-ctas__text .widget--button .btn {
  display: unset;
}
.w-ctas--blockstype-textbelow .w-ctas__imagelink[href], .w-ctas--blockstype-bgimage .w-ctas__imagelink[href] {
  overflow: hidden;
}
@media (hover: hover) {
  .w-ctas--blockstype-textbelow .w-ctas__imagelink[href]:hover .w-ctas__image, .w-ctas--blockstype-bgimage .w-ctas__imagelink[href]:hover .w-ctas__image {
    transform: var(--ww-widget-ctas-image-transform-hover);
  }
}
.w-ctas--blockstype-textbelow .w-ctas__image, .w-ctas--blockstype-bgimage .w-ctas__image {
  transition: transform 0.3s;
}
.w-ctas--blockstype-textbelow .w-ctas__col {
  border-radius: var(--ww-widget-ctas-image-border-radius);
  box-shadow: var(--ww-widget-ctas-col-box-shadow);
  overflow: hidden;
}
.w-ctas--blockstype-textbelow .w-ctas__col-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.w-ctas--blockstype-textbelow .w-ctas__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.w-ctas--blockstype-textbelow .w-ctas__cta-readmorecontainer {
  margin-top: auto;
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkoverlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ww-widget-ctas-image-overlay);
  transition: opacity 0.3s;
}
@media (hover: hover) {
  .w-ctas--blockstype-bgimage .w-ctas__imagelinkoverlay:hover {
    opacity: var(--ww-widget-ctas-image-overlay-opacity-hover);
  }
}
.w-ctas--blockstype-bgimage .w-ctas__text > * {
  color: var(--ww-widget-ctas-text-color);
}
.w-ctas--blockstype-bgimage .w-ctas__imagelink {
  border-radius: var(--ww-widget-ctas-image-border-radius);
}
.w-ctas--blockstype-bgcolor .w-ctas__text {
  justify-content: flex-start;
  text-decoration: none;
  position: static;
  height: auto;
  min-height: 100%;
  color: inherit;
  pointer-events: auto;
  border-radius: var(--ww-widget-ctas-image-border-radius);
  transition: background 0.3s;
}
.w-ctas--blockstype-bgcolor .w-ctas__text p {
  color: rgba(25, 25, 89, 0.6);
}
@media (hover: hover) {
  .w-ctas--blockstype-bgcolor .w-ctas__text:hover {
    background: var(--ww-widget-ctas-text-background-color-hover);
  }
}

.w-ctas--overwrite-aspectratio .w-ctas__imagelink {
  height: auto;
  padding-bottom: 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-blog-index .c-filters__filterbuttons {
  margin-bottom: 2rem;
}

html.page-blog-details .blogger {
  background-color: red;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  width: calc(100vw - 2rem);
  display: grid;
  grid-template-areas: "imagecontainer imagecontainer" "name date";
  justify-content: center;
  padding: 1rem;
  gap: 0.5rem 1rem;
}
html.page-blog-details .blogger .blogger__imagecontainer {
  grid-area: imagecontainer;
}
html.page-blog-details .blogger .blogger__name {
  grid-area: name;
}
html.page-blog-details .blogger .blogger__date {
  grid-area: date;
}
html.page-blog-details .blogger__image-name-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
html.page-blog-details .blogger__name {
  white-space: nowrap;
  font-weight: 700;
}
html.page-blog-details .blogger__imagecontainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
html.page-blog-details .blogger__image,
html.page-blog-details .blogger__image-placeholder {
  width: 76px;
  aspect-ratio: 1;
  border-radius: 100%;
}
html.page-blog-details .blogger__image {
  object-fit: cover;
}
html.page-blog-details .blogger__image-placeholder {
  background: var(--color-pink);
}
html.page-blog-details .blogger__date {
  white-space: nowrap;
}
@media (max-width: 991.98px) {
  html.page-blog-details main {
    margin-top: 8rem;
  }
  html.page-blog-details .blogger {
    transform: translate(-50%, calc(50% + 1rem));
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  html.page-blog-details main {
    margin-top: 6rem;
  }
  html.page-blog-details .blogger {
    grid-template-columns: 76px 1fr min-content;
    grid-template-areas: "imagecontainer name date";
    gap: 16px;
    justify-content: space-between;
    padding: 25px 30px;
    width: 500px;
    max-width: 100vw;
  }
}

.w-form {
  font-family: "proxima-nova", "sans-serif";
  padding: 6rem 0;
  color: #fff;
  container-type: inline-size;
}
.w-form .wh-form__fieldgroup {
  margin-bottom: 2rem;
}
.w-form .w-form__titleleft,
.w-form .w-form__titletop {
  margin-top: 0;
}
.w-form .wh-form__page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 12px;
}
.w-form .wh-form__fieldgroup.wh-form__doublecol,
.w-form .wh-form__fieldgroup.wh-form__fieldgroup--richtext {
  grid-column: span 2;
}
.w-form .wh-form__fieldgroup.wh-form__fieldgroup--richtext {
  color: #000;
  margin-bottom: 1rem;
}
.w-form .wh-form__fieldgroup.wh-form__fieldgroup--richtext a {
  color: inherit;
}
.w-form .wh-form__fieldgroup.wh-form__fieldgroup--richtext h2,
.w-form .wh-form__fieldgroup.wh-form__fieldgroup--richtext h3 {
  margin-top: 0;
  color: #fff;
}
.w-form .wh-form__page[data-wh-form-pagerole=thankyou] {
  display: unset;
}
.w-form .wh-form__button, .w-form .hbspt-form .hs-button, .hbspt-form .w-form .hs-button {
  text-transform: uppercase;
}
.w-form__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  background: #f40076;
  padding: 3rem;
}
@media screen and (max-width: 1200px) {
  .w-form__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@container (inline-size > 1200px) {
  .w-form__container {
    padding: 5rem;
  }
}
.w-form__cols {
  display: flex;
  justify-content: space-between;
}
.w-form__col {
  width: 50%;
  font-size: 16px;
  line-height: 24px;
}
.w-form__col--left {
  margin-right: 40px;
}
.w-form__col--right {
  margin-left: 40px;
}
.w-form__col .wh-rtd > *:first-child {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .w-form {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .w-form__cols {
    flex-direction: column;
  }
  .w-form__col {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container-xxl, .navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

:root {
  --color-pink: #f40076;
}

.offcanvas {
  --bs-offcanvas-bg: #fff;
}

.wh-anchor {
  scroll-margin-top: 180px;
}

.dropdown-menu {
  --bs-dropdown-font-size: 1rem;
}

.ww-chevron--down {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.ww-chevron--down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #fff;
  width: 30px;
  height: 2px;
}

.hamburger-inner:before {
  top: -8px;
}

.hamburger-inner:after {
  bottom: -8px;
}

.footer {
  position: relative;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.footer__newsletter {
  margin-top: 100px;
  padding-top: 100px;
  margin-top: 0;
  padding-top: 0;
  position: relative;
  transform: translateY(-60px);
  z-index: 999;
}
.footer__newsletter .wh-form__fieldgroup.hide_in_footer {
  display: none;
}
.footer__container {
  margin: 0 auto;
  width: 100%;
  max-width: 792px;
  display: flex;
  flex-flow: row wrap;
  padding: 100px 0 130px;
}
@media screen and (max-width: 792px) {
  .footer__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.footer__col {
  width: 25%;
}
.footer__col--0 .footer__titleborder:nth-child(1) {
  width: 40px;
  background: #f40076;
}
.footer__col--1 .footer__titleborder:nth-child(1) {
  width: 40px;
  background: #000;
}
.footer__col--2 .footer__titleborder {
  width: 13px;
  background: #d9d1d5;
  margin-right: 2px;
}
.footer__col--3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer__coltitle {
  font-size: 14px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  color: #4f3b46;
}
.footer__titlebordercontainer {
  margin: 0 0 40px;
}
.footer__titleborder {
  display: inline-block;
  height: 6px;
  border-radius: 20px;
}
.footer__linkcontainer {
  margin: 0 0 10px;
}
.footer__link {
  font-size: 16px;
  line-height: 100%;
  text-decoration: none;
  font-family: "Montserrat", "sans-serif";
  font-weight: 300;
  color: #4f3b46;
}
html.non-touch .footer__link:hover {
  text-decoration: underline;
}
.footer__logocontainer, .footer__socialcontainer {
  margin: 0 0 40px;
}
.footer__socialcontainer {
  display: flex;
}
.footer__socialcontainer > a {
  margin-left: 30px;
}
.footer__socialcontainer > a svg {
  display: block;
}
.footer__line {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translate(-708px, -687px);
  bottom: 0;
  top: auto;
  transform: none;
  left: 0;
  transform: translateY(calc(-100% + 100px));
}
@media screen and (max-width: 768px) {
  .footer__container {
    flex-flow: row wrap;
  }
  .footer__col {
    width: 33.33333%;
  }
  .footer__col:last-child {
    width: 100%;
    gap: 2rem;
    margin-top: 0;
    align-items: center;
  }
  .footer__col:last-child .footer__logocontainer {
    margin-right: 0;
  }
  .footer__col:last-child > * {
    margin-top: 0;
  }
  .footer__logocontainer {
    margin-bottom: 0;
  }
  .footer__logocontainer img {
    display: block;
  }
  .footer__socialcontainer > a:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .footer__container {
    padding-top: 125px;
    padding-bottom: 125px;
  }
  .footer__col {
    width: 100%;
  }
  .footer__titlebordercontainer {
    margin-bottom: 20px;
  }
  .footer__links {
    margin-bottom: 40px;
  }
}

.footer__certs {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .footer__col--3 {
    margin-top: 2rem !important;
  }
  .footer__col--3 {
    align-items: flex-start;
    gap: 2rem;
  }
  .footer__socialcontainer {
    margin-bottom: 0;
  }
  .footer__certs {
    justify-content: center;
    gap: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .footer__col {
    width: 50% !important;
  }
  .footer__col--3 {
    margin-top: 0.5rem !important;
    align-items: flex-start !important;
  }
  .footer__certs {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 450px) {
  .footer__col {
    width: 100% !important;
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}
.breadcrumb, .breadcrumb__link {
  font-size: 16px;
  line-height: 100%;
  color: #fff;
}
.breadcrumb a {
  text-decoration: none;
}
html.non-touch .breadcrumb a:hover {
  text-decoration: underline;
}
.breadcrumb__link--last {
  color: #f40076;
  font-weight: 700;
}
.breadcrumb__divider {
  margin: 0 5px;
}

.button, .hs-cta-node .cta_button,
.wh-form__button,
.hbspt-form .hs-button {
  font-size: 18px;
  line-height: 100%;
  font-family: "Montserrat", "sans-serif";
  color: #f40076;
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, color 0.3s;
  padding: 0 30px 0;
  outline: none;
  color: #f40076;
  border: 2px solid #f40076;
  height: 56px;
  text-decoration: none;
  font-weight: 500;
  min-width: 225px;
  position: relative;
}
html.non-touch .button:hover, html.non-touch .hs-cta-node .cta_button:hover, .hs-cta-node html.non-touch .cta_button:hover,
html.non-touch .wh-form__button:hover,
html.non-touch .hbspt-form .hs-button:hover,
.hbspt-form html.non-touch .hs-button:hover {
  background: #f40076;
  color: #fff;
}
.button__loader,
.wh-form__button__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.button__loader .loader,
.wh-form__button__loader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 25px));
}
.button.loading, .hs-cta-node .loading.cta_button,
.wh-form__button.loading,
.hbspt-form .loading.hs-button {
  pointer-events: none;
}
.button.loading .button__loader, .hs-cta-node .loading.cta_button .button__loader,
.wh-form__button.loading .button__loader,
.hbspt-form .loading.hs-button .button__loader {
  opacity: 1;
}
.button--pinkbg,
.wh-form__button--pinkbg {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
html.non-touch .button--pinkbg:hover,
html.non-touch .wh-form__button--pinkbg:hover {
  background: #fff;
  color: #f40076;
}
.button--filled, .hs-cta-node .cta_button, main > .wh-form .wh-form__button--submit,
.wh-form__button--filled {
  background: #f40076;
  color: #fff;
}
html.non-touch .button--filled:hover, html.non-touch .hs-cta-node .cta_button:hover, .hs-cta-node html.non-touch .cta_button:hover, html.non-touch main > .wh-form .wh-form__button--submit:hover, main > .wh-form html.non-touch .wh-form__button--submit:hover,
html.non-touch .wh-form__button--filled:hover {
  background: #fff;
  color: #f40076;
}
.button--filter,
.wh-form__button--filter {
  border: 1px solid #ccc;
  border-radius: 1px;
  color: #4e3a45;
  display: inline-block;
  font-size: 15px;
  height: unset;
  font-weight: 600;
  padding: 1rem 1rem;
  min-height: unset;
  min-width: unset;
}
.button--filter.selected,
.wh-form__button--filter.selected {
  background: #f40076;
  color: #fff;
}
.button__3dload,
.wh-form__button__3dload {
  width: 225px;
  height: 56px;
  perspective: 800px;
}
.button__3dload.loading .button__inner,
.wh-form__button__3dload.loading .button__inner {
  transform: rotateX(-90deg);
}
.button__inner,
.wh-form__button__inner {
  position: relative;
  height: 56px;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: 0.4s all;
}
.button__front,
.wh-form__button__front {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(0deg) translate3d(0px, 0px, 28px);
}
.button__bottom,
.wh-form__button__bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(90deg) translate3d(0px, 0px, 28px);
}

.hbspt-form .hs-button {
  background: #f40076;
  color: #fff;
}
html.non-touch .hbspt-form .hs-button:hover {
  background: #fff;
  color: #f40076;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.newsblock {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 0 32px 0 rgba(89, 67, 80, 0.1);
  background: #fff;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .newsblock {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.newsblock__grid {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
  display: flex;
  min-height: 320px;
}
.newsblock__col--left {
  flex: 1;
  position: relative;
}
.newsblock__col--right {
  width: 700px;
  padding: 50px 50px 50px 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.newsblock__image {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: calc(100% + 100px);
}
.newsblock__itemtitle {
  font-size: 20px;
  line-height: 100%;
  font-weight: 900;
  color: #4c4c4c;
  margin: 0 0 40px;
}
.newsblock__link {
  margin: 10px 0 0;
}
.newsblock__link a {
  text-decoration: none;
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  color: #f40076;
  letter-spacing: 0.3px;
}
html.non-touch .newsblock__link a:hover {
  text-decoration: underline;
}
.newsblock__navigationcontainer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}
html.isie .newsblock__navigationcontainer {
  bottom: 50px;
}
.newsblock__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: #fff;
  outline: none;
}
.newsblock__navigation:last-child {
  background: #b3b3b2;
}
@media screen and (max-width: 900px) {
  .newsblock__col--left {
    display: none;
  }
  .newsblock__col--right {
    width: 100%;
  }
}
@media screen and (max-width: 710px) {
  .newsblock__grid {
    min-height: 0;
  }
  .newsblock__col--right {
    padding: 40px 0;
  }
}

html.page-webhareform main {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
}
@media screen and (max-width: 996px) {
  html.page-webhareform main {
    padding-left: 25px;
    padding-right: 25px;
  }
}
html.page-webhareform main > .wh-form .wh-form__page--visible {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
html.page-webhareform main > .wh-form .wh-form__fieldgroup {
  width: calc(50% - 30px);
}
html.page-webhareform main > .wh-form .wh-form__fieldgroup--richtext, html.page-webhareform main > .wh-form .wh-form__fieldgroup--textarea {
  width: 100%;
}
@media screen and (max-width: 700px) {
  html.page-webhareform main > .wh-form .wh-form__fieldgroup {
    width: 100%;
  }
}

body {
  overflow-x: hidden;
}

main {
  position: relative;
  z-index: 15;
}

main,
footer,
.header__logo,
.header__menu,
.heroimage,
.zipchecker,
.websiterouteline,
.heroimage__videocontainergrid {
  filter: blur(0);
  transition: filter 0.6s;
}
html.menu-open main, html.zipresult-active main,
html.menu-open footer,
html.zipresult-active footer,
html.menu-open .header__logo,
html.zipresult-active .header__logo,
html.menu-open .header__menu,
html.zipresult-active .header__menu,
html.menu-open .heroimage,
html.zipresult-active .heroimage,
html.menu-open .zipchecker,
html.zipresult-active .zipchecker,
html.menu-open .websiterouteline,
html.zipresult-active .websiterouteline,
html.menu-open .heroimage__videocontainergrid,
html.zipresult-active .heroimage__videocontainergrid {
  filter: blur(15px);
  pointer-events: none;
}

html.menu-open .header__logo,
html.zipresult-active .header__logo {
  opacity: 0;
}

.emb-button {
  text-align: center;
}

.blogrelatedpages {
  margin-top: 5rem;
}
.blogrelatedpages .h2 {
  margin-bottom: 2rem;
  text-align: center;
}

.blogrelatedpages--single .w-ctas__container {
  display: flex;
  justify-content: center;
}
.blogrelatedpages--single .w-ctas__col {
  width: 384px;
}

html.page-zipcheckerpage .zipchecker {
  position: absolute;
  top: 350px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 576px) {
  html.page-zipcheckerpage .zipchecker {
    position: static;
    transform: none;
    margin-bottom: 50px;
  }
}
html.page-zipcheckerpage .heroimage {
  margin-bottom: 360px !important;
  margin-bottom: 406px !important;
}
@media screen and (max-width: 576px) {
  html.page-zipcheckerpage .heroimage {
    margin-bottom: 50px !important;
  }
}

.websiterouteline {
  pointer-events: none;
}
@media screen and (max-width: 1200px) {
  .websiterouteline {
    display: none !important;
  }
}

.hs-cta-node {
  display: flex;
  justify-content: center;
}
html.page-webhareform h2,
html.page-webhareform p {
  text-align: left;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.p-news__slider .slick-list,
.p-carousel__slider .slick-list {
  max-width: 1200px;
  margin: 0px auto;
}

html.page-overview main {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {
  html.page-overview main {
    padding-left: 25px;
    padding-right: 25px;
  }
}
html.page-overview main > .heading2 {
  max-width: unset;
  margin-top: 64px;
  padding-left: 0 !important;
}

html.page-overview .w-ctas__col,
.blogrelatedpages .w-ctas__col {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
html.page-overview .w-ctas__col a,
.blogrelatedpages .w-ctas__col a {
  color: var(--color-pink);
}
html.page-overview .w-ctas__text,
.blogrelatedpages .w-ctas__text {
  display: flex;
  flex-direction: column;
}
html.page-overview .w-ctas__cta-subtitle,
.blogrelatedpages .w-ctas__cta-subtitle {
  order: -1;
  margin-bottom: 0;
  font-style: italic;
  font-size: 90%;
}

.page-back-link a {
  color: var(--color-pink);
  text-decoration: none;
}

.wh-video,
.wh-video > iframe {
  width: 100% !important;
  aspect-ratio: 16/9;
}

.wh-requireconsent {
  position: relative;
  z-index: 0;
}

.wh-requireconsent__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.wh-requireconsent__overlay a {
  color: inherit;
}
.wh-requireconsent__overlay[hidden] {
  display: none;
}

.wh-video__innerframe__preview img {
  width: 100%;
  height: auto;
  display: block;
}